export default class RmRoutesConstant {
  // For telemetry appName
  public static readonly RelationshipManagement = 'RelationshipManagement';

  // For /rmdelivery
  public static readonly RmDeliveryRouteName = 'rmdelivery';
  public static readonly RmDeliveryRouteUrl = '/mydeliveries';

  // For /relationshipmanagementdashboard
  public static readonly RmDashboardRouteName = 'relationship_management_dashboard';
  public static readonly RmDashboardRouteUrl = '/relationshipmanagementdashboard';

  // For /rmreports
  public static readonly RmReportsRoute = 'rmreports';
  public static readonly RmReportsRouteUrl = '/rmreports';

  // For /rmsearchreports
  public static readonly RmSearchReportsRoute = 'rmsearchreports';
  public static readonly RmSearchReportsRouteUrl = '/rmsearchreports';

  // For /reportsunauthorized
  public static readonly RmSearchReportsUnauthorizedRoute = 'reportsunauthorized';
  public static readonly RmSearchReportsUnauthorizedRouteUrl = `/reportsunauthorized`;
  public static readonly RmSearchReportsUnAuthorizedOwnerCoOwnerList = 'ownersList';
  public static readonly sdpId = 'sdpId';
  public static readonly sdpName = 'sdpName';

  public static readonly RmReportCases = 'rmreportcases';
  public static readonly RmReportCasesRouteUrl = '/rmreportcases';

  public static readonly RmGenerateReport = 'rmgeneratereport';
  public static readonly RmGenerateReportRouteUrl = '/rmgeneratereport';

  // For /relationshipmanagementdashboard / Summary
  public static readonly RmDashboardSummary = 'relationship_management_dashboard.summary';
  public static readonly RmDashboardSummaryRouteUrl = '/summary';

  // For /relationshipmanagementdashboard /  Agreements
  public static readonly RmDashboardAgreements = 'relationship_management_dashboard.agreements';
  public static readonly RmDashboardAgreementsRouteUrl = '/agreements';

  // For /relationshipmanagementdashboard /  Programs
  public static readonly RmDashboardPrograms = 'relationship_management_dashboard.programs';
  public static readonly RmDashboardProgramsRouteUrl = `/supportprojects`;

  // For /relationshipmanagementdashboard /  Deliveries
  public static readonly RmDashboardDeliveries = 'relationship_management_dashboard.deliveries';
  public static readonly RmDashboardDeliveriesRouteUrl = `/deliveries`;

  // For /relationshipmanagementdashboard /  Cases
  public static readonly RmDashboardCases = 'relationship_management_dashboard.cases';
  public static readonly RmDashboardCasesRouteUrl = `/cases`;

  // For /relationshipmanagementdashboard /  Utilization
  public static readonly RmDashboardUtilizationView = 'relationship_management_dashboard.utilization';
  public static readonly RmDashboardUtilizationViewUrl = '/utilization';

  // For /relationshipmanagementdashboard /  Accounts
  public static readonly RmDashboardAccounts = 'relationship_management_dashboard.accounts';
  public static readonly RmDashboardAccountsRouteUrl = `/accounts`;

  // For /relationshipmanagementdashboard /  CSAT
  public static readonly CSATView = 'relationship_management_dashboard.csat';
  public static readonly CSATViewUrl = '/csat';

  // For /relationshipmanagementdashboard  / CSPM
  public static readonly CloudKnowsPremier = 'relationship_management_dashboard.cspm';
  public static readonly CloudKnowsPremierUrl = '/cspm';

  public static readonly CloudKnowsPremierSearch = 'relationship_management_dashboard.cspm.search';
  public static readonly CloudKnowsPremierSearchUrl = '/search/{grid}';

  // For /relationshipmanagementdashboard /  Software Assurance Benefits
  public static readonly RmDashboardSoftwareAssurance = 'relationship_management_dashboard.software-assurance-benefits';
  public static readonly RmDashboardSoftwareRouteUrl = '/software-assurance-benefits';

  // For /dmm
  public static readonly DmmRouteName = 'dmm';
  public static readonly DmmRouteUrl = '/csammanager';

  // For /dmmmportfolio
  public static readonly DmmPorfolioRouteName = 'dmm.portfolio';
  public static readonly DmmPortfolioRouteUrl = '/portfolio';
  public static readonly DmmPortfolio = 'DmmPortfolio';

  // For Dmm /  Utilization
  public static readonly DmmUtilization = 'dmm.utilization';
  public static readonly DmmUtilizationRouteUrl = `/utilization`;

  // For Dmm / ActionView
  public static readonly DmmActionView = 'dmm.actionview';
  public static readonly DmmActionViewRouteUrl = `/actionview`;

  // For Dmm /  SDPReview
  public static readonly DmmSdpReview = 'dmm.sdpreview';
  public static readonly DmmSdpReviewRouteUrl = `/sdpreview`;

  // For Dmm /  CPESurvey
  public static readonly DmmCpeSurvey = 'dmm.cpesurvey';
  public static readonly DmmCpeSurveyRouteUrl = `/cpesurvey`;

  // For Dmm /  CPESurveyDetails
  public static readonly DmmCpeSurveyDetails = 'cpedetails';
  public static readonly DmmCpeSurveyDetailsRouteUrl = `/csammanager/cpesurvey/cpedetails/surveyId/{surveyId}/packageId/{packageId}/regionType/{regionType}`;

  // For Dmm /  Software Assurance Benefits
  public static readonly DmmSoftwareAssurance = 'dmm.software-assurance-benefits';
  public static readonly DmmSoftwareRouteUrl = '/software-assurance-benefits';

  // For Dmm /  CSPM
  public static readonly DmmCkp = 'dmm.cspm';
  public static readonly DmmCkpRouteUrl = '/cspm';

  // For Program Management /  Utilization
  public static readonly CKPView = 'rmdelivery.cspm';
  public static readonly CKPViewUrl = '/cspm';

  // For /rm
  public static readonly RmRouteName = 'rm';
  public static readonly RmRouteUrl = '/rm';
  public static readonly RmRouteParamTab = 'tab';
  public static readonly RmRouteParamItemType = 'itemtype';
  public static readonly RmRouteAccountItemType = 'Account';
  public static readonly RmRoutePortfolioItemType = 'Portfolio';
  public static readonly RmRouteTabSummary = 'Summary';
  public static readonly RmRouteTabContacts = 'Contacts';
  public static readonly RmRouteTabPrograms = 'Programs';
  public static readonly RmRouteTabAccount = 'Accounts';
  public static readonly RmRouteTabOpportunities = 'Opportunities';
  public static readonly RmRouteTabSuccessEngagements = 'Success Engagements';


  // For /rm/account*
  public static readonly AccountRouteName = 'account';
  public static readonly AccountRouteUrl = `${RmRoutesConstant.RmRouteUrl}/account/:accountid/:tab`;
  public static readonly AccountRouteParamTab = 'tab';
  public static readonly AccountRouteParamAccountId = 'accountid';
  public static readonly AccountRouteTabSummary = 'Summary';
  public static readonly AccountRouteTabOpportunities = 'Opportunities';
  public static readonly AccountRouteTabContacts = 'Contacts';
  public static readonly AccountRouteAccountItemType = 'Account';
  public static readonly AccountRouteTabSuccessEngagements = 'Success Engagements';

  // For Program Management
  public static readonly ProgramManagement = 'rmprogrammanagement';
  public static readonly ProgramManagementRouteUrl = `/supportprojectmanagement`;

  // For Program Management /  Summary
  public static readonly PortfolioSummary = 'rmdelivery.summary';
  public static readonly PortfolioSummaryRouteUrl = `/summary`;
  public static readonly PortfolioTabSummary = 'Summary';
  public static readonly PortfolioTabPrograms = 'Programs';
  public static readonly PortfolioTabDeliveries = 'Deliveries';
  public static readonly PortfolioTabAccounts = 'Accounts';

  // For Program Management /  Accounts
  public static readonly PortfolioAccounts = 'rmdelivery.accounts';
  public static readonly PortfolioAccountsRouteUrl = `/accounts`;

  // For Program Management /  Programs
  public static readonly PortfolioPrograms = 'rmdelivery.programs';
  public static readonly PortfolioProgramsRouteUrl = `/supportprojects`;

  // For Program Management /  Deliveries
  public static readonly PortfolioDeliveries = 'rmdelivery.deliveries';
  public static readonly PortfolioDeliveriesRouteUrl = `/deliveries`;

  // For Program Management /  Unassigned Packages
  public static readonly UnassignedPackages = 'rmdelivery.unassignedpackages';
  public static readonly UnassignedPackagesRouteUrl = `/unassignedpackages`;

  public static readonly UnlinkedRequests = 'rmdelivery.unlinkedrequests';
  public static readonly UnlinkedRequestsRouteUrl = `/unlinkedrequests`;

   // For Program Management /  unlinked projects in React framework
  public static readonly UnlinkedProjects = 'rmdelivery.unlinkedprojects';
  
  // For Program Management /  Cases
  public static readonly PortfolioCases = 'rmdelivery.cases';
  public static readonly PortfolioCasesRouteUrl = `/cases`;

  // For Program Management /  Agreements
  public static readonly PortfolioAgreements = 'rmdelivery.agreements';
  public static readonly PortfolioAgreementsRouteUrl = '/agreements';

  // For Program Management /  Utilization
  public static readonly PortfolioUtilizationView = 'rmdelivery.utilization';
  public static readonly PortfolioUtilizationViewUrl = '/utilization';

  // For Program Management /  SDP
  public static readonly ServiceDeliveryPlan = 'rmdelivery.sdp';
  public static readonly ServiceDeliveryPlanRouteUrl = `/sdps/{sdpId}`;
  public static readonly SdpRouteParamSdpId = 'sdpId';

  // For Program Management /  SDP / Summary
  public static readonly SdpSummary = 'rmdelivery.sdp.summary';
  public static readonly SdpSummaryRouteUrl = `/summary`;

  // For Program Management /  SDP / Programs
  public static readonly SdpPrograms = 'rmdelivery.sdp.programs';
  public static readonly SdpProgramsRouteUrl = `/supportprojects`;

  // For Support Projects in React framework
  public static readonly SupportProjects = 'rmdelivery.sdp.supportprojects';

  // For Program Management /  SDP / Create Program
  // public static readonly SdpCreateProgram = 'rmdelivery.sdp.programs.new';
  public static readonly SdpCreateProgram = 'program_create';
  public static readonly SdpCreateProgramRouteUrl = `/mydeliveries/sdps/{sdpId}/supportprojects/new`;

  public static readonly SdpEditPrograms = 'program_edit';
  public static readonly SdpEditProgramsRouteUrl = `/mydeliveries/sdps/{sdpId}/supportprojects/{programId}/edit`;

  // For Program Management /  SDP / Deliveries
  public static readonly SdpDeliveries = 'rmdelivery.sdp.deliveries';
  public static readonly SdpDeliveriesRouteUrl = `/deliveries`;

  // For Program Management /  SDP / Contacts
  public static readonly SdpContacts = 'rmdelivery.sdp.contacts';
  public static readonly SdpContactsRouteUrl = `/contacts`;

  // For Program Management /  SDP / access id
  public static readonly SdpAccessId = 'rmdelivery.sdp.accessid';
  public static readonly SdpAccessIdRouteUrl = `/accessid`;

  // For Program Management /  SDP / View Program
  public static readonly SdpViewProgram = 'rmdelivery.sdp.viewprogram';
  public static readonly SdpViewProgramRouteUrl = `/supportprojects/{programId}`;
  public static readonly SdpProgramRouteParamProgramId = 'programId';
  public static readonly SdpProgramRouteParamIsProspectiveCustomer = 'isProspectiveCustomer';


  // For Program Management /  SDP / Edit Program
  public static readonly SdpEditProgram = 'rmdelivery.sdp.editprogram';
  public static readonly SdpEditProgramRouteUrl = `/supportprojects/{programId}/edit`;

  // For Program Management /  SDP / Create Delivery
  public static readonly SdpCreateDelivery = 'rmdelivery.sdp.createdelivery';
  public static readonly SdpCreateDeliveryRouteUrl = `/deliveries/create`;

  // For Program Management /  SDP / View Delivery
  public static readonly SdpViewDelivery = 'rmdelivery.sdp.viewdelivery';
  public static readonly SdpViewDeliveryRouteUrl = `/deliveries/{deliveryId}`;
  public static readonly SdpDeliveryRouteParamDeliveryId = 'deliveryId';

  // For Program Management /  SDP / Edit Delivery
  public static readonly SdpEditDelivery = 'rmdelivery.sdp.editdelivery';
  public static readonly SdpEditDeliveryRouteUrl = `/deliveries/{deliveryId}/edit`;

  // For Program Management /  SDP / Reports
  public static readonly SdpReports = 'rmdelivery.sdp.reports';
  public static readonly SdpReportsRouteUrl = `/reports`;

  // For Program Management /  SDP / Outcomes
  public static readonly SdpOutcomes = 'rmdelivery.sdp.outcomes';
  public static readonly SdpOutcomesRouteUrl = `/outcomes`;

  // For Program Management /  SDP / Agreements
  public static readonly SdpAgreements = 'rmdelivery.sdp.agreements';
  public static readonly SdpAgreementsRouteUrl = `/agreements`;

  // For Program Management /  SDP / Reviews
  public static readonly SdpReviews = 'rmdelivery.sdp.reviews';
  public static readonly SdpReviewsRouteUrl = `/reviews`;

  // For Program Management /  SDP / SuccessEngagement
  public static readonly SuccessEngagement = 'rmdelivery.sdp.successengagement';
  public static readonly SuccessEngagementUrl = `/successengagement`;
  
  // Leads Routes

  // This route doesn't have to be explicit since it's being built from child routes
  public static readonly SdpLeads = 'rmdelivery.sdp.supportneeds';
  public static readonly SdpLeadsRouteUrl = `/supportneeds`;

  // public static readonly SdpCreateLead = 'rmdelivery.sdp.leads.new';
  public static readonly SdpCreateLead = 'supportneeds_create';
  public static readonly SdpCreateLeadRouteUrl = `/mydeliveries/sdps/{sdpId}/supportneeds/new`;

  public static readonly SdpEditLead = 'supportneeds_edit';
  public static readonly SdpEditLeadRouteUrl = `/mydeliveries/sdps/{sdpId}/supportneeds/{leadId}/edit`;


  // For Program Management /  SDP / Package / Summary
  public static readonly SDPPackageSummary = 'rmdelivery.sdp.packageSummary';
  public static readonly PackageSummaryRouteUrl = `/packages/{combinedPackageId}/overview`;
  public static readonly PackageRouteCombinedPackageId = 'combinedPackageId';

  // For Program Management /  SDP / Package / Consumption
  public static readonly SdpConsumption = 'rmdelivery.sdp.consumption';
  public static readonly PackageConsumptionRouteUrl = `/packages/{combinedPackageId}/consumption`;

  // For Program Management / SDP / Package / Service
  public static readonly SdpPackageService = 'rmdelivery.sdp.packageService';
  public static readonly PackageServiceRouteUrl = `/packages/{combinedPackageId}/service`;

  // For Program Management / SDP / Package / Cases
  public static readonly SdpCases = 'rmdelivery.sdp.packageCases';
  public static readonly PackageCasesRouteUrl = `/packages/{combinedPackageId}/cases`;

  // For Program Management /  SDP / Package / Contacts
  public static readonly SdpPackageCustomerContacts = 'rmdelivery.sdp.packageCustomerContacts';
  public static readonly PackageCustomerContactsRouteUrl = `/packages/{combinedPackageId}/customercontacts`;

  // For Program Management /  SDP / Package / Contacts
  public static readonly SdpPackageContacts = 'rmdelivery.sdp.packageContacts';
  public static readonly PackageContactsRouteUrl = `/packages/{combinedPackageId}/contacts`;

  // For Program Management / SDP / Package / Mailing List
  public static readonly SdpPackageMailingList = 'rmdelivery.sdp.packageMailingList';
  public static readonly PackageMailingListRouteUrl = `/packages/{combinedPackageId}/schedule-mailing-list`;

  // For Program Management / Package / Summary
  public static readonly UnassignedPackageSummary = 'rmdelivery.packageSummary';

  // For Program Management / Package / Consumption
  public static readonly UnassignedPackageConsumption = 'rmdelivery.consumption';

  // For Program Management / Package / Service
  public static readonly UnassignedPackageService = 'rmdelivery.packageService';

  // For Program Management / Package / Cases
  public static readonly UnassignedPackageCases = 'rmdelivery.packageCases';

  // For Program Management /  Package / Contacts
  public static readonly UnassignedPackageContacts = 'rmdelivery.packageContacts';

  // For Program Management /  SDP / Summary
  public static readonly UnAuthorized = 'rmdelivery.sdp.unauthorized';
  public static readonly UnAuthorizedRouteUrl = `/unauthorized`;
  public static readonly UnAuthorizedOwnerCoOwnerList = 'OwnerCoOwnerList';

  // For /queries
  public static readonly QueryRouteName = 'rmdelivery.queries';
  public static readonly QueryRouteUrl = '/queries/{queryName}';

  // For /managequeries
  public static readonly ManageQueriesRouteName = 'rmdelivery.manageQueries';
  public static readonly ManageQueriesRouteUrl = '/manageQueries';



  // For Program Management /  Agreements / Cases
  public static readonly AgreementCase = 'rmdelivery.agreementCases';
  public static readonly AgreementRouteCombinedAgreementId = 'combinedAgreementId';
  public static readonly AgreementCaseRouteUrl = '/agreement/{combinedAgreementId}/cases';

  // For Program Management /  Agreements / Consumption
  public static readonly AgreementConsumption = 'rmdelivery.agreementSdpConsumption';
  public static readonly AgreementConsumptionRouteUrl = `/agreements/{combinedAgreementId}/consumption`;

  // For Program Management /  Agreements / Details
  public static readonly AgreementDetails = 'rmdelivery.agreementsDetails';
  public static readonly AgreementDetailseRouteUrl = '/agreement/{combinedAgreementId}/details';
}
