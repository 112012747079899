import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://rmux-nonprod.azureedge.net/servicesint-ux',
  serviceEndPoints: [
    {
      clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/rmsvcsit/',
    },
    {
      clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
      serviceEndPoint: 'https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net'
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/',
    },
    {
      clientId: '181ef486-9e47-4ad6-9454-7a326432dc00',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/dmuat/',
    }
  ],
  RmAppConfiguration: {}
};
