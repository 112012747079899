import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const aurorasit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://rmux.blob.core.windows.net/aurorasit',
  serviceEndPoints: [
    {
      clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/rmsvcsit/',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/',
    },
  ],
  RmAppConfiguration: {
    'oneProfile': 'https://oneprofilesitapipack-aurora.azurewebsites.net/api/v1/profile/searchprofile?searchText=%s',
  }
};
