import { settings } from '../configs/appSettings';
import { aurorasit } from '../configs/appSettings.aurorasit';
import { dev } from '../configs/appSettings.dev';
import { prod } from '../configs/appSettings.prod';
import { sit } from '../configs/appSettings.sit';
import { uat } from '../configs/appSettings.uat';
import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';
import { Environment } from '@fxp/fxpservices';

export const mergeObjects = (destination: object, ...sources: object[]): object => {
    const sourceLength = sources.length;
    for (let objIndex = 0; objIndex < sourceLength; objIndex++) {
        const source = sources[objIndex];
        if (typeof (source) === 'object') {
            const keys = Object.keys(source);
            const keysLength = keys.length;
            Object.keys(source).forEach((key) => {
                const prop = source[key];
                if (typeof (prop) === 'object') {
                    if (!typeof (destination[key] === 'object')) {
                        destination[key] = Array.isArray(prop) ? [] : {};
                    }
                    mergeObjects(destination[key], prop);

                } else {
                    destination[key] = prop;
                }
            });
            for (let keyIndex = 0; keyIndex < keysLength; keyIndex++) {

            }
        }
    }
    return destination;
};


export const appSettings = (): IPartnerAppSettings => {
    let environmentAppSetting;
    switch (window['FxpAppSettings'].EnvironmentName) {
        case Environment.Dev: environmentAppSetting = dev; break;
        case Environment.Sit: environmentAppSetting = sit; break;
        case Environment.Uat: environmentAppSetting = uat; break;
        case Environment.AuroraSit: environmentAppSetting = aurorasit; break;
        case Environment.Prod: environmentAppSetting = prod; break;
        default: environmentAppSetting = dev; break;
    }

    // properties in later spreads “win out” over previously created properties.
    const mergedSettings = mergeObjects(settings, environmentAppSetting);

    return mergedSettings as IPartnerAppSettings;
};

