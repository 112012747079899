import { IStateConfig, IAppService, IFxpAppContext, IServiceEndPoints, IPartnerBundle, IRouteInfo } from '@fxp/fxpservices';
import RmRoutesConstant from './app/common/rm-routes-constant';
import { StateParams } from '@uirouter/core';
import { appSettings } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class RMApp_routes implements IAppService {

  getRoutes(fxpAppContext: IFxpAppContext): IRouteInfo {

    const stateCollection: Array<IStateConfig> = [
      {
        name: RmRoutesConstant.DmmRouteName,
        url: RmRoutesConstant.DmmRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Relationship Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementView',
          featureName: 'Management.ActionView', // Default landing page in Management
          actionName: 'View',
          breadcrumbText: 'CSAM Manager',
          pageTitle: 'CSAM Manager',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.DmmPorfolioRouteName,
        url: RmRoutesConstant.DmmPortfolioRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Relationship Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementPortfolio',
          featureName: 'Management.Portfolio',
          actionName: 'View',
          breadcrumbText: 'Portfolio',
          pageTitle: 'Portfolio',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.DmmUtilization,
        url: RmRoutesConstant.DmmUtilizationRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Utilization',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementUtilization',
          featureName: 'Management.Utilization',
          actionName: 'View',
          breadcrumbText: 'Utilization',
          pageTitle: 'Utilization',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.DmmActionView,
        url: RmRoutesConstant.DmmActionViewRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'ActionView',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementActionView',
          featureName: 'Management.ActionView',
          actionName: 'View',
          breadcrumbText: 'ActionView',
          pageTitle: 'ActionView',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.DmmSdpReview,
        url: RmRoutesConstant.DmmSdpReviewRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Review',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementSDPReview',
          featureName: 'Management.SDPReview',
          actionName: 'View',
          breadcrumbText: 'Customer Review',
          pageTitle: 'Customer Review',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.DmmCpeSurvey,
        url: RmRoutesConstant.DmmCpeSurveyRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'CPE Survey',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementCPESurveys',
          featureName: 'Management.CPESurveys',
          actionName: 'View',
          breadcrumbText: 'CPE Survey',
          pageTitle: 'CPE Survey',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.DmmCpeSurveyDetails,
        url: RmRoutesConstant.DmmCpeSurveyDetailsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'CPE Details',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementCPESurveyDetails',
          featureName: 'Management.CPESurveyDetails',
          actionName: 'View',
          breadcrumbText: 'CPE Details',
          pageTitle: 'CPE Details',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ surveyId: { dynamic: true }, packageId: { dynamic: true }, regionType: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.DmmSoftwareAssurance,
        url: RmRoutesConstant.DmmSoftwareRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Software Assurance Benefits',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementSoftwareAssuranceBenefits',
          featureName: 'Management.SoftwareAssuranceBenefits',
          actionName: 'View',
          breadcrumbText: 'Software Assurance Benefits',
          pageTitle: 'Software Assurance Benefits',
          style: 'icon icon-people'
        },
        includeRootModule: true,
      },
      {
        name: RmRoutesConstant.DmmCkp,
        url: RmRoutesConstant.DmmCkpRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Cloud Support Plan Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManagementCSPM',
          featureName: 'Management.CSPM',
          actionName: 'View',
          breadcrumbText: 'Cloud Support Plan Management',
          pageTitle: 'Cloud Support Plan Management',
          style: 'icon icon-people'
        },
        includeRootModule: true,
      },
      {
        name: RmRoutesConstant.RmDeliveryRouteName,
        url: RmRoutesConstant.RmDeliveryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Relationship Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'MyCustomers',
          featureName: 'Customers',
          actionName: 'View',
          breadcrumbText: 'Customers',
          pageTitle: 'Customers',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.RmRouteName,
        url: RmRoutesConstant.RmRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Relationship Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomersPortfolio', // loads under dashboard accounts
          featureName: 'Customers.MyPortfolio',
          actionName: 'View',
          breadcrumbText: 'My Portfolio',
          pageTitle: 'My Portfolio',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.RmDashboardRouteName,
        url: RmRoutesConstant.RmDashboardRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Relationship Management Dashboard',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RelationshipManagementDashboard',
          featureName: 'RMDashboard.DashboardSummary',  // landing page
          actionName: 'View',
          breadcrumbText: 'Dashboard',
          pageTitle: 'Dashboard',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.RmDashboardSummary,
        url: RmRoutesConstant.RmDashboardSummaryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'RM Dashboard Summary',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RelationshipManagementDashboardSummary',
          featureName: 'RMDashboard.DashboardSummary',
          actionName: 'View',
          breadcrumbText: 'Summary',
          pageTitle: 'RM Dashboard Summary',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.UnAuthorized,
        url: RmRoutesConstant.UnAuthorizedRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Unauthorized',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'Unauthorized',
          featureName: 'RMDashboard.CustomerUnauthorized',
          actionName: 'Unauthorized',
          breadcrumbText: 'Unauthorized',
          pageTitle: 'Customer Unauthorized',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpName: { dynamic: true }, ownersList: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.RmDashboardAgreements,
        url: RmRoutesConstant.RmDashboardAgreementsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'RM Dashboard Agreement',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardAgreements',
          featureName: 'RMDashboard.Agreements',
          actionName: 'View',
          breadcrumbText: 'Agreement',
          pageTitle: 'RM Dashboard Agreement',
          style: 'icon icon-people'
        },
        includeRootModule: true,
      },
      {
        name: RmRoutesConstant.RmDashboardSoftwareAssurance,
        url: RmRoutesConstant.RmDashboardSoftwareRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Software Assurance Benefits',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardSoftwareAssuranceBenefits',
          featureName: 'RMDashboard.SoftwareAssuranceBenefits',
          actionName: 'View',
          breadcrumbText: 'Software Assurance Benefits',
          pageTitle: 'Software Assurance Benefits',
          style: 'icon icon-people'
        },
        includeRootModule: true,
      },
      {
        name: RmRoutesConstant.RmDashboardPrograms,
        url: RmRoutesConstant.RmDashboardProgramsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'RM Dashboard Support Projects',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardPrograms',
          featureName: 'RMDashboard.Programs',
          actionName: 'View',
          breadcrumbText: 'Support Projects',
          pageTitle: 'RM Dashboard Support Projects',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.RmDashboardDeliveries,
        url: RmRoutesConstant.RmDashboardDeliveriesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'RM Dashboard Deliveries',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardDeliveries',
          featureName: 'RMDashboard.Deliveries',
          actionName: 'View',
          breadcrumbText: 'Deliveries',
          pageTitle: 'RM Dashboard Deliveries',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.RmDashboardCases,
        url: RmRoutesConstant.RmDashboardCasesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'RM Dashboard Cases',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardCases',
          featureName: 'RMDashboard.Cases',
          actionName: 'View',
          breadcrumbText: 'Cases',
          pageTitle: 'RM Dashboard Cases',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.RmDashboardUtilizationView,
        url: RmRoutesConstant.RmDashboardUtilizationViewUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'RM Dashboard Utilization',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardUtilization',
          featureName: 'RMDashboard.Utilization',
          actionName: 'View',
          breadcrumbText: 'Utilization',
          pageTitle: 'RM Dashboard Utilization',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.RmDashboardAccounts,
        url: RmRoutesConstant.RmDashboardAccountsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'RM Dashboard Accounts',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardAccounts',
          featureName: 'RMDashboard.Accounts',
          actionName: 'View',
          breadcrumbText: 'Accounts',
          pageTitle: 'RM Dashboard Accounts',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.CloudKnowsPremier,
        url: RmRoutesConstant.CloudKnowsPremierUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Cloud Support Plan Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardCSPM',
          featureName: 'RMDashboard.CSPM',
          actionName: 'View',
          breadcrumbText: 'Cloud Support Plan Management',
          pageTitle: 'Cloud Support Plan Management',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.CloudKnowsPremierSearch,
        url: RmRoutesConstant.CloudKnowsPremierSearchUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Cloud Support Plan Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardCSPM',
          featureName: 'RMDashboard.SearchAndMatch',
          actionName: 'SearchAndMatch',
          breadcrumbText: 'Cloud Support Plan Management Search',
          pageTitle: 'Cloud Support Plan Management',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ gridName: { dynamic: true }, tpid: { dynamic: true }, grid: { dynamic: true}, filter: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.AccountRouteName,
        url: RmRoutesConstant.AccountRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Relationship Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'MyPortfolio', // not able to locate in UI
          featureName: 'MyPortfolio',
          actionName: 'MyPortfolio',
          breadcrumbText: 'My Portfolio',
          pageTitle: 'My Portfolio',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.ProgramManagement,
        url: RmRoutesConstant.ProgramManagementRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Support Project Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerProgramManagement', // not able to locate in UI
          featureName: 'Program',
          subFeatureName: 'ProgramManagement',
          actionName: 'View',
          breadcrumbText: 'Customers',
          pageTitle: 'Customers',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.PortfolioSummary,
        url: RmRoutesConstant.PortfolioSummaryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Portfolio Summary',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'PortfolioSummary', // not able to locate in UI
          featureName: 'Summary',
          actionName: 'View',
          breadcrumbText: 'Summary',
          pageTitle: 'Portfolio Summary',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.PortfolioAccounts,
        url: RmRoutesConstant.PortfolioAccountsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Accounts',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'PortfolioAccounts', // not able to locate in UI
          featureName: 'Accounts',
          actionName: 'View',
          breadcrumbText: 'Accounts',
          pageTitle: 'Accounts',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.PortfolioPrograms,
        url: RmRoutesConstant.PortfolioProgramsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Support Projects',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'PortfolioPrograms', // not able to locate in UI
          featureName: 'Programs',
          actionName: 'View',
          breadcrumbText: 'Support Projects',
          pageTitle: 'Support Projects',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.PortfolioDeliveries,
        url: RmRoutesConstant.PortfolioDeliveriesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Deliveries',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'PortfolioDeliveries', // not able to locate in UI
          featureName: 'Deliveries',
          actionName: 'View',
          breadcrumbText: 'Deliveries',
          pageTitle: 'Deliveries',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.UnassignedPackages,
        url: RmRoutesConstant.UnassignedPackagesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Unassigned Packages',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnassignedPackages',
          featureName: 'UnassignedPackages',
          actionName: 'View',
          breadcrumbText: 'Unassigned Packages',
          pageTitle: 'Unassigned Packages',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.UnlinkedRequests,
        url: RmRoutesConstant.UnlinkedRequestsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Unlinked Requests',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnlinkedRequests',
          featureName: 'UnlinkedRequests',
          actionName: 'View',
          breadcrumbText: 'Unlinked Requests',
          pageTitle: 'Unlinked Requests',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.PortfolioCases,
        url: RmRoutesConstant.PortfolioCasesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Cases',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'PortfolioCases', // not able to locate in UI
          featureName: 'Cases',
          actionName: 'View',
          breadcrumbText: 'Cases',
          pageTitle: 'Cases',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.PortfolioAgreements,
        url: RmRoutesConstant.PortfolioAgreementsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Agreements',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'PortfolioAgreements', // not able to locate in UI
          featureName: 'Agreements',
          actionName: 'View',
          breadcrumbText: 'Agreements',
          pageTitle: 'Agreements',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.PortfolioUtilizationView,
        url: RmRoutesConstant.PortfolioUtilizationViewUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Utilization',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'PortfolioUtilization', // not able to locate in UI
          featureName: 'Utilization',
          actionName: 'View',
          breadcrumbText: 'Utilization',
          pageTitle: 'Utilization',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.CKPView,
        url: RmRoutesConstant.CKPViewUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Cloud Support Plan Management',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ProgramManagementCSPM', // not able to locate in UI
          featureName: 'ProgramManagement.CSPM ',
          actionName: 'View',
          breadcrumbText: 'Cloud Support Plan Management',
          pageTitle: 'Cloud Support Plan Management',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.CSATView,
        url: RmRoutesConstant.CSATViewUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'CPE',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'RMDashboardCPE',
          featureName: 'RMDashboard.CPE',
          actionName: 'View',
          breadcrumbText: 'CPE',
          pageTitle: 'CPE',
          style: 'icon icon-people'
        },
        includeRootModule: true
      },
      {
        name: RmRoutesConstant.ServiceDeliveryPlan,
        url: RmRoutesConstant.ServiceDeliveryPlanRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Name',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerDetail', // not able to view in UI
          featureName: 'Customers.CustomerDetail',
          actionName: 'View',
          breadcrumbText: 'Customer Name',
          pageTitle: 'Customer Name',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpSummary,
        url: RmRoutesConstant.SdpSummaryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Summary',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerSummary',
          featureName: 'Customers.Customer.Summary',
          actionName: 'View',
          breadcrumbText: 'Summary',
          pageTitle: 'Customer Summary',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpPrograms,
        url: RmRoutesConstant.SdpProgramsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Support Projects',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPrograms',
          featureName: 'Customers.Customer.Programs',
          actionName: 'View',
          breadcrumbText: 'Support Projects',
          pageTitle: 'Customer Support Projects',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, isNewSupportProjectExperience: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpDeliveries,
        url: RmRoutesConstant.SdpDeliveriesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Deliveries',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerDeliveries',
          featureName: 'Customers.Customer.Deliveries',
          actionName: 'View',
          breadcrumbText: 'Deliveries',
          pageTitle: 'Customer Deliveries',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpContacts,
        url: RmRoutesConstant.SdpContactsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Microsoft Contacts',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerMsftContacts',
          featureName: 'Customers.Customer.MsftContacts',
          actionName: 'View',
          breadcrumbText: 'Contacts',
          pageTitle: 'Microsoft Contacts',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpConsumption,
        url: RmRoutesConstant.PackageConsumptionRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Consumption',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPacakgeConsumption',
          featureName: 'Customers.Packages.Consumption',
          actionName: 'View',
          breadcrumbText: 'Consumption',
          pageTitle: 'Customer Consumption',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpAccessId,
        url: RmRoutesConstant.SdpAccessIdRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Access ID',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerAccessID', // not able to locate in UI
          featureName: 'AccessID',
          actionName: 'View',
          breadcrumbText: 'Access ID',
          pageTitle: 'Customer Access ID',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpCreateDelivery,
        url: RmRoutesConstant.SdpCreateDeliveryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Create Delivery',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerDeliveries',
          featureName: 'Customers.Customer.Deliveries', // not able to locate in UI
          actionName: 'Create',
          breadcrumbText: 'Create Delivery',
          pageTitle: 'Create Delivery',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, isProspectiveCustomer: { dynamic: true} })
      },
      {
        name: RmRoutesConstant.SdpViewProgram,
        url: RmRoutesConstant.SdpViewProgramRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'View Support Project',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerProgramDetail',
          featureName: 'Customers.Customer.ProgramDetail',
          actionName: 'View',
          breadcrumbText: 'View Support Project',
          pageTitle: 'View Support Project',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, programId: { dynamic: true }, isProspectiveCustomer: { dynamic: true} })
      },
      {
        name: RmRoutesConstant.SdpEditProgram,
        url: RmRoutesConstant.SdpEditProgramRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Edit Support Project',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPrograms',
          featureName: 'Customers.Customer.Programs',
          actionName: 'Edit',
          breadcrumbText: 'Edit Support Project',
          pageTitle: 'Edit Support Project',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, programId: { dynamic: true }, isProspectiveCustomer: { dynamic: true} })
      },
      {
        name: RmRoutesConstant.SdpViewDelivery,
        url: RmRoutesConstant.SdpViewDeliveryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'View Delivery',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerDeliveries',
          featureName: 'Customers.Customer.Deliveries',
          actionName: 'View',
          breadcrumbText: 'View Delivery',
          pageTitle: 'View Delivery',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, serviceId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpEditDelivery,
        url: RmRoutesConstant.SdpEditDeliveryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Edit Delivery',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerDeliveries', // loading under deliveries view url
          featureName: 'Deliveries',
          actionName: 'Edit',
          breadcrumbText: 'Edit Delivery',
          pageTitle: 'Edit Delivery',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, serviceId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpReports,
        url: RmRoutesConstant.SdpReportsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Reports',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerReports',
          featureName: 'Customers.Customer.Reports',
          actionName: 'View',
          breadcrumbText: 'Reports',
          pageTitle: 'Customer Reports',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpOutcomes,
        url: RmRoutesConstant.SdpOutcomesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Outcomes',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPrograms',
          featureName: 'Customers.Customer.Programs',
          subFeatureName: 'Outcomes',
          actionName: 'View',
          breadcrumbText: 'Outcomes',
          pageTitle: 'Customer Outcomes',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpCases,
        url: RmRoutesConstant.PackageCasesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Cases',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPackageCases',
          featureName: 'Customers.Packages.Cases',
          actionName: 'View',
          breadcrumbText: 'Cases',
          pageTitle: 'Customer Cases',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpAgreements,
        url: RmRoutesConstant.SdpAgreementsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Agreement',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerAgreements',
          featureName: 'Customers.Packages.Agreements',
          actionName: 'View',
          breadcrumbText: 'Agreement',
          pageTitle: 'Customer Agreement',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpReviews,
        url: RmRoutesConstant.SdpReviewsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Reviews',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerReviews',
          featureName: 'Customers.Packages.Reviews',
          actionName: 'View',
          breadcrumbText: 'Reviews',
          pageTitle: 'Customer Reviews',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SuccessEngagement,
        url: RmRoutesConstant.SuccessEngagementUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Success Engagements',
          breadcrumbText: 'Success Engagements',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'Success Engagements',
          featureName: 'SuccessEngagements',
          actionName: 'View',
          pageTitle: 'Customer Success Engagements',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpLeads,
        url: RmRoutesConstant.SdpLeadsRouteUrl,
        // url: '/mydeliveries/sdps/:sdpId/leads',

        // component: LeadsGridContainerComponent,
generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Support Needs',
          breadcrumbText: 'Support Needs',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerSupportNeeds',
          featureName: 'Customers.Customer.SupportNeeds',
          actionName: 'View',
          pageTitle: 'Customer Support Needs',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpEditLead,
        url: RmRoutesConstant.SdpEditLeadRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Support Needs',
          breadcrumbText: 'Support Needs',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerSupportNeeds',
          featureName: 'Customers.Customer.SupportNeeds',
          actionName: 'Edit',
          pageTitle: 'Customer Support Needs',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, leadId: { dynamic: true }, lead: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpCreateLead,
        url: RmRoutesConstant.SdpCreateLeadRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Support Needs',
          breadcrumbText: 'Support Needs',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerSupportNeed',
          featureName: 'Customers.Customer.SupportNeeds',
          actionName: 'Create',
          pageTitle: 'Customer Support Needs',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SDPPackageSummary,
        url: RmRoutesConstant.PackageSummaryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Package',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPackageOverview',
          featureName: 'Customers.Packages.Overview',
          actionName: 'View',
          breadcrumbText: 'Overview',
          pageTitle: 'Customer Package',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpPackageCustomerContacts,
        url: RmRoutesConstant.PackageCustomerContactsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Package Contacts',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPackageCustomerContacts',
          featureName: 'Customers.Packages.CustomerContacts',
          actionName: 'View',
          breadcrumbText: 'Contacts',
          pageTitle: 'Customer Package Contacts',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpPackageContacts,
        url: RmRoutesConstant.PackageContactsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Package Contacts',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerandMsftContacts',
          featureName: 'Customers.Customer.MsftContacts',
          actionName: 'View',
          breadcrumbText: 'Contacts',
          pageTitle: 'Package Contacts',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpPackageService,
        url: RmRoutesConstant.PackageServiceRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Package Service',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPackageServices',
          featureName: 'Customers.Packages.Services',
          actionName: 'View',
          breadcrumbText: 'Service',
          pageTitle: 'Customer Package Service',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.SdpPackageMailingList,
        url: RmRoutesConstant.PackageMailingListRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Package Mailing List',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CustomerPackageMailingList',
          featureName: 'Customers.Packages.MailingList',
          actionName: 'View',
          breadcrumbText: 'Mailing List',
          pageTitle: 'Customer Package Mailing List',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.UnassignedPackageSummary,
        url: RmRoutesConstant.PackageSummaryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Package',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnassignedPackagesOverview',
          featureName: 'UnassignedPackages.Overview',
          actionName: 'View',
          breadcrumbText: 'Overview',
          pageTitle: 'Package Summary',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.UnassignedPackageContacts,
        url: RmRoutesConstant.PackageContactsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Package Contacts',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnassignedPackagesContacts',
          featureName: 'UnassignedPackages.Contacts',
          actionName: 'View',
          breadcrumbText: 'Contacts',
          pageTitle: 'Package Contacts',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.UnassignedPackageService,
        url: RmRoutesConstant.PackageServiceRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Package Service',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnassignedPackagesServices',
          featureName: 'UnassignedPackages.Services',
          actionName: 'View',
          breadcrumbText: 'Service',
          pageTitle: 'Package Service',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.UnassignedPackageCases,
        url: RmRoutesConstant.PackageCasesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Cases',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnassignedPackagesCases',
          featureName: 'UnassignedPackages.Cases',
          actionName: 'View',
          breadcrumbText: 'Cases',
          pageTitle: 'Cases',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.UnassignedPackageConsumption,
        url: RmRoutesConstant.PackageConsumptionRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Consumption',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnassignedPackagesConsumption',
          featureName: 'UnassignedPackages.Consumption',
          actionName: 'View',
          breadcrumbText: 'Consumption',
          pageTitle: 'Consumption',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ combinedPackageId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.QueryRouteName,
        url: RmRoutesConstant.QueryRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Query',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'CSAM/AgreementSearchQuery',
          featureName: 'Customers.Search',
          actionName: 'Query',
          breadcrumbText: 'Query',
          pageTitle: 'Query',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ queryName: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.ManageQueriesRouteName,
        url: RmRoutesConstant.ManageQueriesRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Manage Queries',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ManageSearchQuery',
          featureName: 'Customers.Search.ManageQueries',
          actionName: 'Manage',
          breadcrumbText: 'Manage Queries',
          pageTitle: 'Manage Queries',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ tab: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.AgreementCase,
        url: RmRoutesConstant.AgreementCaseRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Agreement Case',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'AgreementCases',
          featureName: 'Agreement.Cases',
          actionName: 'View',
          breadcrumbText: 'Cases',
          pageTitle: 'Agreement Case',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ combinedAgreementId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.AgreementConsumption,
        url: RmRoutesConstant.AgreementConsumptionRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Consumption ',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'AgreementConsumption',
          featureName: 'Agreement.Consumption',
          actionName: 'View',
          breadcrumbText: 'Consumption',
          pageTitle: 'Consumption',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ combinedAgreementId: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.RmReportsRoute,
        url: RmRoutesConstant.RmReportsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Reports',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ReportsPlanningAndDeliveryDetails',
          featureName: 'Reports.Reports',
          actionName: 'View',
          breadcrumbText: 'Reports',
          pageTitle: 'Reports',
          style: 'icon icon-people'
        },
        includeRootModule: true,
      },
      {
        name: RmRoutesConstant.RmSearchReportsRoute,
        url: RmRoutesConstant.RmSearchReportsRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Search Reports',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'ReportsPlanningAndDeliveryDetails',
          featureName: 'Reports.Reports',
          actionName: 'SearchAndViewReports',
          breadcrumbText: 'Search Reports',
          pageTitle: 'Search Reports',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ sdpId: { dynamic: true }, sdpName: { dynamic: true } })
      },
      {
        name: RmRoutesConstant.RmSearchReportsUnauthorizedRoute,
        url: RmRoutesConstant.RmSearchReportsUnauthorizedRouteUrl,

generatedBundle: '0570f6fd3d7f-RMAppModule-Bundle',
        data: {
          headerName: 'Customer Unauthorised for Report',
          partnerTelemetryName: 'RM',
          appNameForTelemetry: RmRoutesConstant.RelationshipManagement,
          pageNameForTelemetry: 'UnauthorisedView',
          featureName: 'CustomerUnauthorisedforReport',
          actionName: 'Unauthorised',
          breadcrumbText: 'Unauthorised for Report',
          pageTitle: 'Customer Unauthorised for Report',
          style: 'icon icon-people'
        },
        includeRootModule: true,
        params: new StateParams({ OwnerCoOwnerList: { dynamic: true }, sdpName: { dynamic: true } })
      },
    ];
    const routeInfo: IRouteInfo = {
      applicationName: 'RM',
      sharedBundles: [],
      routes: stateCollection
    };

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  public getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    let bundlesData = [
      {
        name: 'RM-Bundle',
        files: [
          `${baseUrl}/vendor.bundle.js`,
          `${baseUrl}/styles.bundle.js`,
          `${baseUrl}/main.bundle.js`
        ],
        sequentialLoading: true
      }
    ];
    return bundlesData;
  }

 public getGeneratedBundles(){
 let currentScriptUrl = document.currentScript['src']; 
let baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/')); 
 return [
{
                name: '0570f6fd3d7f-RMAppModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RMAppModule.vendor.bundle.js',
                    baseUrl + '/RMAppModule.bundle.js'
                ],
                sequentialLoading: true           
            },

]
 
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(RMApp_routes, 'RM');
