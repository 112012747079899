import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const settings: IPartnerAppSettings = {
    cdnBaseUrl: 'https://rmux.blob.core.windows.net/servicesint',
    serviceEndPoints: [
        {
            clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
            serviceEndPoint: 'https://professionalservicesint.microsoft.com/rmsvcsit/'
        },
        {
            clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
            serviceEndPoint: 'https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net'
        },
        {
            clientId: '00000003-0000-0000-c000-000000000000',
            serviceEndPoint: 'https://graph.microsoft.com/',
        },
        {
            clientId: 'c8955af6-b4a7-4e96-86b7-ad0930c25fa7',
            serviceEndPoint: 'https://dmsitafd.azurefd.net/',
        }
    ],
    RmAppConfiguration: {
        'rmServiceBaseUri': 'https://professionalservicesint.microsoft.com/rmsvcsit',
        'rmGraphQLBaseUri': 'https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net',
        'graphApiBaseUri': 'https://graph.microsoft.com/v1.0',
        'graphApiBatchSize': 20,
        'PartnerEndpoints': [
            {
                'Endpoint': 'https://professionalservicesint.microsoft.com/rmsvcsit',
                'ResourceId': 'c1df0735-5f8f-4429-9977-80e732362bdd'
            },
            {
                'Endpoint': 'https://graph.microsoft.com/',
                'ResourceId': '00000003-0000-0000-c000-000000000000'
            }
        ],
        'rmServiceApi': {
            'AccountDetails': '/api/Accounts/%s/details',
            'AccountOpportunities': '/api/accounts/%s/opportunities?pageNumber=%s&includeProducts=%s',
            'SdpProgram': '/api/sdps/%s/Programs',
            'EditProgram': '/api/sdps/%s/Programs/%s',
            'DeleteSdpProgram': '/api/sdps/%s/Programs/delete',
            'GetPortfolioPrograms': '/api/Programs',
            'DeliveriesByAlias': '/api/deliveries',
            'GetBasicDeliveriesBySdpId': '/api/sdps/%s/basicdeliveries',
            'GetDeliveriesBySdpId': '/api/sdps/%s/deliveries',
            'GetDeliveriesBySdpIdReports': '/api/report/sdps/%s/deliveries',
            'GetBasicDeliveriesByProgramId': '/api/sdps/%s/programs/%s/basicdeliveries',
            'GetDeliveriesByProgramId': '/api/sdps/%s/programs/%s/deliveries',
            // Packages is deprecated by GetPackages
            'Packages': '/api/packages?packageType=%s&%s&userAlias=%s',
            'GetPackages': '/api/packages?packageType=%s&%s&userAlias=%s',
            // PackageById is deprecated by GetPackageById
            'PackageById': '/api/packages/%s?includeServices=%s&includeAgreement=%s&includeContacts=%s&filterServices=%s&excludeCKPContacts=%s',
            'GetPackageById': '/api/packages/%s?includeServices=%s&includeAgreement=%s&includeContacts=%s&filterServices=%s&excludeCKPContacts=%s',
            // AssignedPackages is deprecated by GetSdpPackages
            'AssignedPackages': '/api/sdps/%s/packages?filterPackagesWithoutDeliveries=%s&%s&includeServices=%s',
            'GetSdpPackages': '/api/sdps/%s/packages?filterPackagesWithoutDeliveries=%s&%s&includeServices=%s&includeContacts=%s',
            // PackageConsumption is deprecated by GetPackageConsumptions
            'PackageConsumption': '/api/packages/%s/consumptions?',
            'GetPackageConsumptions': '/api/packages/%s/consumptions?',
            // tamSearchGroupBySdp is deprecated by GetSdpListByAlias
            'tamSearchGroupBySdp': '/api/sdps?tamAlias=%s&includeInactive=%s',
            'GetSdpListByAlias': '/api/sdps?tamAlias=%s&includeInactive=%s',
            // AdjustmentsEligible is deprecated by AdjustmentsEligibility
            'AdjustmentsEligible': '/api/Packages/%s/Services/%s/IsEligible',
            'AdjustmentsEligibility': '/api/Packages/%s/Services/%s/IsEligible',
            // AdjustmentList is deprecated by GetAdjustmentDetails
            'AdjustmentList': '/api/Packages/%s/Services/%s/Adjustments',
            'GetAdjustmentDetails': '/api/Packages/%s/Services/%s/Adjustments?regionType=%s&componentCode=%s',
            'MsftSdpTeamContacts': '/api/sdps/%s/msftsdpteamcontacts',
            // Offerings is deprecated by GetOfferings
            'Offerings': '/api/Offerings',
            'GetOfferings': '/api/Offerings',
            'GetOffering': '/api/Offerings/%s',
            'CustomerContacts': '/api/Packages/%s/customerContacts',
            'OrgUnitsBySdpId': '/api/sdps/%s/OrgUnits',
            'CreateMsftSdpTeamContact': '/api/sdps/%s/MsftSdpTeamContacts',
            'EditMsftSdpTeamContact': '/api/sdps/%s/MsftSdpTeamContacts/%s',
            'DeleteMsftSdpTeamContact': '/api/sdps/%s/MsftSdpTeamContacts/delete',
            'AssignPackageToSdp': '/api/sdps/%s/packages',
            'GetAirportCodes': '/api/DeliveryRequests/airportcodes',
            'GetWorldLanguages': '/api/DeliveryRequests/languages',
            'BillingChoices': '/api/DeliveryRequests/billingchoices',
            'GetDressCodes': '/api/DeliveryRequests/dresscodes',
            'GetTransportationChoices': '/api/DeliveryRequests/transportations',
            'GetCountries': '/api/DeliveryRequests/countries',
            'GetStateChoices': '/api/DeliveryRequests/GetStatesByCountry?countryCode=%s',
            'GetCityChoices': '/api/DeliveryRequests/GetCitiesByStateAndCountry?countryCode=%s&stateName=%s',
            // Cities is deprecated by GetCityChoices
            'Cities': '/api/DeliveryRequests/GetCitiesByStateAndCountry?countryCode=%s&stateName=%s',
            'GetTimeZoneChoices': '/api/DeliveryRequests/timezones',
            'SDPSummaryDetails': '/api/sdps/%s',
            'AgreementRenewalMetrics': '/api/Agreements/renewalmetrics',
            'Agreements': '/api/Agreements',
            'AgreementDetail': '/api/Agreements/%s?InstallationSiteId=%d&FetchFromCache=false',
            'DmmAgreements': '/api/Agreements/directreports',
            'AssignedPackagesReports': '/api/report/sdps/%s/packages?filterPackagesWithoutDeliveries=%s&%s&includeServices=%s',
            'PackageConsumptionReports': '/api/report/packages/%s/consumptions?',
            'CreateCustomerContact': '/api/packages/%s/contacts',
            'ReactiveDeliveriesAggregatedByStatus': '/api/reactivedeliveries/status',
            'ReactiveDeliveries': '/api/reactivedeliveries',
            'SdpReactiveDeliveries': '/api/sdps/%s/reactivedeliveries',
            'DeliveryRequestDrafts': '/api/DeliveryRequests/drafts',
            'GetDeliveryById': '/api/Sdps/%s/deliveries/%s',
            'KeyActions': '/api/KeyActions',
            'KeyActionsCompletion': '/api/KeyActions/%s/completionStatus',
            'KeyActionsBySdpId': '/api/KeyActions/sdps/%s',
            'GetCaseDetailsByPackageId': '/api/Packages/%s/cases',
            'AdjustmentReason': '/api/Packages/%s/Services/%s/Reasons',
            'AddAdjustment': '/api/Packages/%s/Services/%s/Adjustments',
            'EditAdjustment': '/api/Packages/%s/Services/%s/Adjustments/%s',
            'DeliveryRequests': '/api/DeliveryRequests',
            'DeliveryReviewDetail': '/api/sdps/deliveryreviewdetail',
            'AccessIdDetailsById': '/api/accessid/%s?packageId=%d',
            'UnMapUsersFromAccessId': '/api/accessid/%s/profiles/unmap',
            // CaseSummary is deprecated by GetCaseSummary
            'CaseSummary': '/api/cases/summary',
            'GetCaseSummary': '/api/cases/summary',
            'ProactiveDeliveriesAggregatedByStatus': '/api/deliveries/status',
            'GetPasswordByAccessId': '/api/accessid/%s',
            'SetPasswordByAccessId': '/api/accessid/%s',
            'DisassociateCustomerContact': '/api/customercontacts/delete',
            'ReactiveCaseDetails': '/api/cases',
            'AssignPackageToNewSdp': '/api/Sdps',
            'DeleteAdjustments': '/api/Packages/%s/Services/%s/Adjustments/Delete',
            'GetPesProductsByName': '/api/Offerings/Products?productName=%s',
            'Skills': '/api/DeliveryRequests/Skills',
            'EditDeliveryDraft': '/api/sdps/%s/deliveries/%s',
            'EditSubmittedDelivery': '/api/sdps/%s/deliveries/%s',
            'EditCustomerContact': '/api/packages/%s/updateContact',
            'DeliveryRequestSync': '/api/DeliveryRequests/sync',
            'CreateAndLinkBasicDelivery': '/api/programs/basicdeliveries',
            'SyncDeliveryByRossId': '/api/sdps/%s/programs/%s/deliverysync/%s',
            'RmUserLogin': '/api/UserLogin',
            'DeleteSdp': '/api/sdps/%s',
            'UnlinkPackageFromSdp': '/api/Sdps/%s/Packages/Delete',
            'DeleteDelivery': '/api/Sdps/%s/deliveries/%s',
            'EditCampusSite': '/api/Sdps/%s/campussites',
            'AddCampusSite': '/api/Sdps/%s/campussites',
            'DeleteCampusSite': '/api/Sdps/%s/campussites/delete',
            'UpdateSdpSPM': '/api/sdps/%s/msftsdpteamcontacts/updateSpm',
            'GetReviewBySdpId': '/api/sdps/%s/SdpReviews',
            'GetReviewBySdpIdReports': '/api/report/sdps/%s/SdpReviews',
            'EditTAMBAMAlias': '/api/Packages/%s',
            'AgreementPackages': '/api/Agreements/%s',
            'tamSearchGroupByAgreement': '/api/Agreements?userAlias=%s&agreementStatus=%s',
            'casesHealthOptions': '/api/Cases/%s/HealthStateDetails',
            'casesHealthDetails': '/api/Cases/%s/HealthDetails',
            'casesAddHealthDetails': '/api/Cases/%s/HealthDetails',
            'GetCaseTrendDetails': '/api/Cases/%s/TrendDetails',
            'GetParentTrendDetails': '/api/Cases/%s/ParentTrendDetails',
            'GetChildTrendDetails': '/api/Cases/%s/%s/ChildTrendDetails',
            'AddTrendDetails': '/api/Cases/%s/TrendDetails',
            'UpdateTrendDetails': '/api/Cases/%s/TrendDetails',
            'sdpReviewDataOptions': '/api/sdps?tamAlias=%s&accessLevel=%s',
            'SdpReviewDataOptionsReports': '/api/report/sdps?tamAlias=%s&accessLevel=%s',
            'SubmitNewSdpReview': '/api/sdps/%s/SdpReviews/Create',
            'GetSurveyHeaders': '/api/Surveys',
            'GetDMMSurveyHeaders': '/api/dmm/Surveys',
            'GetSurveyDetails': '/api/Surveys/%s?packageId=%s&regionType=%s',
            'GetDMMSurveyDetails': '/api/dmm/Surveys/%s?packageId=%s&regionType=%s',
            'GetSurveyResponses': '/api/Surveys/%s/customerSurveyResponse?packageId=%s',
            'GetSurveyOptions': '/api/Surveys/options',
            'UpdateSurvey': '/api/Surveys/%s?packageId=%s&regionType=%s',
            'DMMUpdateSurvey': '/api/dmm/Surveys/%s?packageId=%s&regionType=%s',
            'CreateRCA': '/api/Surveys/%s/rcas?packageId=%s&regionType=%s',
            'UpdateRCA': '/api/Surveys/%s/rcas/%s?packageId=%s&regionType=%s',
            'DeleteRCA': '/api/Surveys/%s/rcas/%s?packageId=%s&regionType=%s',
            'CreateRemediation': '/api/Surveys/%s/rcas/%s/remediationStep?packageId=%s&regionType=%s',
            'UpdateRemediation': '/api/Surveys/%s/rcas/%s/remediationSteps/%s?packageId=%s&regionType=%s',
            'DeleteRemediation': '/api/Surveys/%s/rcas/%s/remediationSteps/%s?packageId=%s&regionType=%s',
            'GetSurveyAggregates': '/api/Surveys/aggregates',
            'GetAzureInternalBillingAccounts': '/api/AzureInternalBillingAccounts',
            'GetAzureInternalAccountsAlert': '/api/azureinternalbillingaccountsalerts',
            'GetAzureBillingAccounts': '/api/azurebillingaccounts',
            'GetAzureAccountsAlert': '/api/azurebillingaccountsalerts',
            'AddAzureSubscription': '/api/azurebillingaccounts/%s/packages',
            'AddAzureInternalSubscription': '/api/azureinternalbillingaccounts/%s/packages',
            'GetO365Tenants': '/api/O365Tenants',
            'GetO365TenantAlerts': '/api/o365tenantsalerts',
            'GetAzureInternalPackagesBySubscriptionId': '/api/azureinternalsubscriptions/%s/probablePackages',
            'GetAzurePackagesBySubscriptionId': '/api/azuresubscriptions/%s/probablePackages',
            'ProgramsSummary': '/api/sdps/%s/Programs',
            'GetProgramDetails': '/api/program/basicDetails',
            'ProgramSummaryForProgram': '/api/sdps/%s/Programs/%s/summary',
            'GetO365ProbableTenants': '/api/o365probabletenants?tenantId=%s&domainName=%s',
            'AddO365ProbableTenantsWithPackage': '/api/o365tenants/%s/packages',
            'UpdateO365Tenants': '/api/o365tenantsmatchings',
            'GetSdpRisksAndIssues': '/api/sdps/%s/issuerisks',
            'GetProgramRisksAndIssues': '/api/sdps/%s/programs/%s/issueRisks',
            'GetMailingList': '/api/packages/%s/mailingLists',
            'AddMailingList': '/api/packages/%s/mailingLists',
            'EditMailingList': '/api/packages/%s/mailingLists/%s',
            'DeleteMailingList': '/api/packages/%s/MailingLists/%s',
            'GetMailingListOptions': '/api/config/dropdowns/%s',
            'GetO365TenantsV2': '/api/v2/o365tenants',
            'UpdateO365TenantsV2': '/api/v2/o365tenants/matchings',
            'GetO365SearchTenantsV2': '/api/v2/o365searchtenants?searchType=%s&searchValue=%s',
            'GetO365Packages': '/api/v2/o365packages',
            'GetAzureBillingAccountsV2': '/api/v2/azurebillingaccounts',
            'UpdateAzureBillingAccountV2': '/api/v2/azurebillingaccounts/matching',
            'GetAzureSearchSubscriptionsV2': '/api/v2/azuresearchsubscriptions?searchType=%s&searchValue=%s',
            'GetAzurePackages': '/api/v2/azurepackages',
            'GetAzureInternalBillingAccountsV2': '/api/v2/AzureInternalBillingAccounts',
            'UpdateAzureInternalBillingAccountV2': '/api/v2/azureinternalbillingaccounts/matching',
            'GetAzureInternalSearchSubscriptionsV2': '/api/v2/azureinternalsearchsubscriptions?subscriptionId=%s',
            'GetAzureInternalPackages': '/api/v2/azureinternalpackages',
            'GetAzureAndInternalSubscriptionsV3': '/api/v3/azuresubscriptions',
            'UpdateAzureUnMatchSubscriptionsV3': '/api/v3/azuresubscriptions/unmatch',
            'UpdateAzureMatchSubscriptionsV3': '/api/v3/azuresubscriptions/match',
            'UpdateSubscriptionNoSupportDetailsV3': '/api/v3/azuresubscriptions/nosupport',
            'GetO365TenantsV3': '/api/v3/o365tenants',
            'UpdateO365UnMatchTenantsV3': '/api/v3/o365tenants/unmatch',
            'UpdateO365MatchTenantsV3': '/api/v3/o365tenants/match',
            'UpdateTenantNoSupportDetailsV3': '/api/v3/o365tenants/nosupport',
            'GetCkpV3Packages': '/api/v3/azuresubscriptions/ckppackages?serviceType=%s',
            'GetEnrollmentDetails': '/api/v3/azuresubscriptions/enrollmentDetails',
            'CreateSdpRisksAndIssues': '/api/sdps/%s/issueRisks',
            'CreateProgramRisksAndIssues': '/api/sdps/%s/programs/%s/issueRisks',
            'SearchByAgreement': '/api/Agreements/Search',
            'GetSdpProgram': '/api/sdps/%s/programs/%s',
            'UpdateAzureInternalBillingAccount': '/api/azureinternalbillingaccountsmatching',
            'UpdateAzureBillingAccount': '/api/azurebillingaccountsmatching',
            'MsftProgramTeamContacts': '/api/sdps/%s/programs/%s/MsftProgramTeamContacts',
            'CreateMsftProgramTeamContacts': '/api/sdps/%s/programs/%s/MsftProgramTeamContacts',
            'DeleteMsftProgramTeamContacts': '/api/sdps/%s/programs/%s/MsftProgramTeamContacts/delete',
            'UpdateMsftProgramTeamContacts': '/api/sdps/%s/programs/%s/MsftProgramTeamContacts/%s',
            'GetSdpActionRegister': '/api/sdps/%s/actions',
            'GetProgramActions': '/api/sdps/%s/programs/%s/actions',
            'EditSdpActionRegister': '/api/sdps/%s/actions/%s',
            'EditProgramActionRegister': '/api/sdps/%s/programs/%s/actions/%s',
            'BillableAccountIdUrlFormat': 'https://cecustomers.microsoftonline.com/#account/%s',
            'TenantIdUrlFormat': 'https://lynx.office.net/#/tenants/%s/health/exchange',
            'GetLeads': '/api/sdps/%s/Leads',
            'GetLeadsGraphQL': '/graphql',
            'GetLead': '/api/sdps/%s/Leads/%s',
            'GetLeadGraphQL': '/graphql',
            'CreateLead': '/api/sdps/%s/Leads',
            'EditLead': '/api/sdps/%s/Leads/%s',
            'DisqualifyLead': '/api/sdps/%s/Leads/%s/disqualify',
            'CreateLeadV1': '/api/v1.0/supportneeds',
            'EditLeadV1': '/api/v1.0/supportneeds',
            'DisqualifyLeadV1': '/api/v1.0/supportneeds/%s/disqualify?customerId=%s',
            'GetLeadOptions': '/api/config/dropdowns/%s',
            'GetLeadOptionsGraphQL': '/graphql',
            'GetOptions': '/api/config/dropdowns/%s',
            'UpdateSdpRiskAndIssues': '/api/sdps/%s/issuerisks/%s',
            'UpdateProgramRiskAndIssues': '/api/sdps/%s/programs/%s/issuerisks/%s',
            'LinkLeadsToProgram': '/api/sdps/%s/Programs/%s/link',
            'DeleteSdpAction': '/api/sdps/%s/actions/delete',
            'GetSdpDecisionLog': '/api/sdps/%s/decisionlogs',
            'AddSdpDecisionLog': '/api/sdps/%s/decisionlogs',
            'EditSdpDecisionLog': '/api/sdps/%s/decisionlogs/%s',
            'EditProgramDecisionLog': '/api/sdps/%s/programs/%s/decisionlogs/%s',
            'DeleteProgramAction': '/api/sdps/%s/programs/%s/actions/delete',
            'DeleteSdpDecisionLog': '/api/sdps/%s/decisionlogs?decisionLogIds=%s',
            'DeleteProgramDecisionLog': '/api/sdps/%s/programs/%s/decisionlogs?decisionLogIds=%s',
            'DeleteSdpRisksAndIssues': '/api/sdps/%s/issuerisks/delete',
            'DeleteProgramRisksAndIssues': '/api/sdps/%s/programs/%s/issuerisks/delete',
            'DeleteProgramDeliveryStatus': '/api/sdps/%s/programs/%s/deliverystatuses/delete',
            'UnlinkLeadsFromProgram': '/api/sdps/%s/programs/%s/unlink',
            'AddProgramDeliveryStatus': '/api/sdps/%s/programs/%s/deliverystatuses',
            'EditProgramDeliveryStatus': '/api/sdps/%s/programs/%s/deliverystatuses/%s',
            'GetProgramDeliveryStatuses': '/api/sdps/%s/programs/%s/deliverystatuses',
            'GetProgramLeads': '/api/sdps/%s/programs/%s/Leads',
            'GetProgramLeadsGraphQL': '/graphql',
            'GetProgramDecisionLog': '/api/sdps/%s/programs/%s/decisionlogs',
            'AddProgramDecisionLog': '/api/sdps/%s/programs/%s/decisionlogs',
            'TransferDeliveries': '/api/sdps/%s/programs/%s/deliveries/transfer',
            'GetSDPCustomerContacts': '/api/sdps/%s/contacts',
            'GetSDPCustomerContact': '/api/sdps/%s/contacts/%s',
            'GetProgramCustomerContacts': '/api/sdps/%s/programs/%s/contacts',
            'CreateSDPCustomerContacts': '/api/sdps/%s/contacts',
            'UpdateProgramCustomerContact': '/api/sdps/%s/programs/%s/contacts/%s',
            'UpdateCustomerContactProperties': '/api/sdps/%s/contacts/%s',
            'GetDispositionByProgramId': '/api/sdps/%s/programs/%s/dispositions',
            'SaveProgramDisposition': '/api/sdps/%s/programs/%s/dispositions',
            'UnlinkCustomerContactFromProgram': '/api/sdps/%s/programs/%s/contacts/delete',
            'LinkCustomerContactToProgram': '/api/sdps/%s/programs/%s/contacts/%s/create',
            'GetPackageMsftContacts': '/api/packages/%s/msftcontacts',
            'AgreementConsumption': '/api/Agreements/%s/Consumptions',
            'CreatePackageMsftContact': '/api/packages/%s/msftcontacts/create',
            'UpdatePackageMsftContact': '/api/packages/%s/msftcontacts/%s',
            'DeletePackageMsftContact': '/api/packages/%s/msftcontacts/%s/%s',
            'GetCasesDetailsByAgreement': '/api/agreements/%s/cases?regionType=%s',
            // PostCasesDetailsByAgreement is Deprecated
            // TODO: REMOVE THIS OLD ENDPOINT WHEN PSR RETIRES
            'PostCasesDetailsByAgreement': '/api/agreements/%s/cases',
            'GetExpenseDecrements': '/api/packages/%s/services/%s/transactions/expenses?regionType=%s&componentCode=%s',
            'CreateExpenseDecrement': '/api/packages/%s/services/%s/transactions/expenses?regionType=%s',
            'UpdateExpenseDecrement': '/api/packages/%s/services/%s/transactions/expenses/%s?regionType=%s',
            'DeleteExpenseDecrement': '/api/packages/%s/services/%s/transactions/expenses/%s?regionType=%s&componentCode=%s',
            'CaseLaborAdjustmentReasons': '/api/case/Adjustments/Reasons?packageId=%s&serviceId=%s&componentCode=%s',
            'CaseAdjustmentdetails': '/api/case/Adjustments?caseNumber=%s',
            'AddCaseLaborAdjustment': '/api/packages/%s/cases/%s',
            'EditCaseLaborAdjustment': '/api/packages/%s/cases/%s',
            'CdsAgreementConsumption': '/api/agreements/%s/Consumptions/region/%s',
            'DeleteCaseLaborAdjustment': '/api/packages/%s/cases/%s/delete',
            'GetBulkMailPackages': '/api/packages/%s',
            'GetProgram': '/api/sdps/%s/programs/%s',
            'CreateProgram': '/api/v2/sdps/%s/programs',
            'CreateProgramFromWizard': '/api/sdps/%s/programs/wizard',
            'EditProgramV2': '/api/v2/sdps/%s/programs/%s',
            'UpdateProgramState': '/api/v2/sdps/%s/programs/%s',
            'GetProgramOutcomes': '/api/sdps/%s/programs/%s/Outcomes',
            'CreateProgramOutcome': '/api/sdps/%s/programs/%s/Outcomes',
            'DeleteProgramOutcomes': '/api/sdps/%s/programs/%s/Outcomes/%s',
            'EditProgramOutcome': '/api/sdps/%s/programs/%s/Outcomes/%s',
            'GetUnifiedDetails': '/api/deliverymetrics/unified-deliveries?userAlias=%s',
            'GetPlanDetails': '/api/deliverymetrics/planned-deliveries?userAlias=%s',
            'GetDeliverOverBookedDetails': '/api/deliverymetrics/overbooked-deliveries?userAlias=%s',
            'GetDeliverBookedDetails': '/api/deliverymetrics/booked-deliveries?userAlias=%s',
            'GetDeliverBehindOnDeliveryDetails': '/api/deliverymetrics/delayed-deliveries?userAlias=%s',
            'GetAzureAndAzureInternalSearchSubscriptionsV3': '/api/v3/azuresubscriptions/search?searchType=%s&searchValue=%s&RegionId=%s',
            'GetO365SearchTenantsV3': '/api/v3/o365tenants/search?searchType=%s&searchValue=%s&RegionId=%s',
            'GetDmmCkpDetails': '/api/matchingmetrics/dmm',
            'GetCSAMCloudSupportDetails': '/api/matchingmetrics', 
            'GetEngagements': '/api/accounts/%s/engagements?ViewType=%s',
            'GetEngagementsBySdp': '/api/sdps/%s/engagements?engagementViewType=%s',
            'GetEngagementsByProgramId': '/api/sdps/%s/programs/%s/engagements',
            'GetMilestones': '/api/accounts/%s/engagements/%s/milestones',
            'GetMilestonesByOpportunityId': '/api/v2/opportunities/%s/milestones?accountId=%s',            
            'GetMilestonesByProgramId': '/api/sdps/%s/programs/%s/milestones',
            'LinkEngagementsToProgram': '/api/sdps/%s/programs/%s/engagements',
            'LinkMilestoneToProgram': '/api/sdps/%s/programs/%s/milestone',
            'UnlinkEngagementsFromProgram': '/api/sdps/%s/programs/%s/engagement/%s?ids=%s',
            'UnlinkMilestonesFromProgram': '/api/sdps/%s/programs/%s/milestone/%s?ids=%s',
            'UnlinkSalesEntitiesFromProgram': '/api/v1/sdps/%s/programs/%s/salesentities?%s&salesEntityType=%s',
            'GetProgramsBySalesEntity': '/api/v1/salesentities/%s/programs?salesEntityType=%s',
            'GetSalesEntitesByProgram':'/api/v1/sdps/%s/programs/%s/salesentities?salesEntityType=%s',
            'LinkSalesEntitiesToProgram':'/api/v1/sdps/%s/programs/%s/salesentities',
            'GetSalesEntitesBySdpId':'/api/v1/sdps/%s/salesentities?salesEntityType=%s',
            'GetCSPMAuditHistory': '/api/v3/cspmaudithistory?searchType=%s&searchValue=%s',
            'DeleteProgram': '/api/sdps/%s/Programs/%s',
            'GetUnlinkedDeliveryRequests': '/api/programs/deliveries/unlinked',
            'LinkDeliveryRequestToProgram': '/api/v1/sdps/%s/programs/%s/manualmatch-program',
            'CreateSupportProjectTemplate': '/api/v1/programTemplates',
            'GetOpportunitiesBySdpId': '/api/sdps/%s/opportunities',
            'GetOpportunitiesBySdpIdV2': '/api/v2/sdps/%s/opportunities?opportunityViewType=%s',
            'CreateNewProspectiveCustomer': '/api/Sdps/prospect',
            'GetFlightedCountries': '/api/config/scopedRequest/flightcountries',
            'GetCustomerDeliverySettingGraphQL':'/graphql',
            'GetUnlinkedSupportProjectsGraphQL':'/graphql',
            'SaveCustomerDeliverySettingGraphQL':'/api/v1.0/customer/%s/deliveries/settings/save'
        },
        'ckpServiceApis':
        {
            'AzureMatches': '/api/Azure/GetBillingAccounts?'
        },
        'opportunities': {
            'msxLink': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=opportunity&id={0}'
        },
        'cloudConsumption': {
            'sccr': 'https://microsoft.sharepoint.com/teams/esbi/cloud%20consumption/pages/default.aspx',
            'cePortalForConsumption': 'https://cecustomers.microsoftonline.com/',
            'us_bsoReporting': 'https://microsoft.sharepoint.com/teams/usmobpo/bso/SitePages/reports-for-msxi.aspx?chromeless=True'
        },
        'serviceFinancials': {
            'tamRolePerformanceDashboard': 'https://msit.powerbi.com/groups/me/reports/c9c3a607-be68-49fe-a89f-c75409c8f0fa/ReportSectionf7f244c90383db8a1c8d',
            'servicesInsightsES': 'https://msit.powerbi.com/groups/56e3ce91-0de0-43bb-a4b3-245db704ea4a/dashboards/283edfd8-d60b-451b-b296-c25b1e09aa'
        },
        'apimSubscriptionKey': '01e62d14a5f04161818c5ef9ee321875',
        'CKPApimSubscriptionKey': '',
        'featureFlighting': {
            'appName': 'Relationship Management Experience',
            'environment': 'dev'
        },
        'oneProfile': 'https://oneprofileuatapipack.azurefd.net/api/v1/profile/searchprofile?searchText=%s',
        'accounts': {
            'numberOfContactsOnSummary': 8
        },
        'offeringsConfig': {
            'voltaComponentCodes': ['AN7', 'AN8', 'ZG6', 'ZG4', 'ZY5', 'ZY6', 'Z1A', 'Z1B', 'Z1C', 'Z1D', 'Z1E', 'Z1F', 'Z1G', 'Z1H', 'Z1I', 'Z1J', 'Z1K', 'Z1L', '0EA', '0EB', '0ED', '0EE', '0ET', '0EU', '0FP', '0FQ'],
            'unifiedComponentCode': 'Y98',
            'pesServiceIds': ['1135', '1136', '1137', '1267', '1284']
        },
        'axisConfig': {
            'requestDetails': 'https://servicesint.azurewebsites.net/#/supportdelivery/requestdetails/%s'
        },
        'casesServiceDeskConfig': {
            'requestDetails': 'https://onesupport.crm.dynamics.com/main.aspx?appid=101acb62-8d00-eb11-a813-000d3a8b3117'
        },
        'casesRaveConfig': {
            'requestDetails': 'https://rave.office.net/cases/%s'
        },
        'cpeConfig': {
            'remediationGuidelinesLink': 'https://sdmplus.azurewebsites.net/sdm/Index#/topics/Remediation%20Guidelines?id=224&ref=sdmplus'
        },
        'msxConfig': {
            'engagementMsxUrlTemplate': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=msp_engagement&id=%s',
            'milestoneMsxUrlTemplate': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=msp_engagementmilestone&id=%s'
        },
        'OneProfileSkillsConfig': {
            'filterConfigDataValue': {
                'orgType': 'CES'
            },
            'skillSearchKeyValue': {
                'tenant': 'RMEX', 
                'purpose': 'Request Creation'
            },
            'uiConfigDataValue': {
                'inputSearch': {
                    'width': '500px',
                    'height': '35px',
                    'fontSize': 14
                }
            },
            'isDisabled': false 
        },
        'supportDeliveryApimSubscriptionKey': 'd3a845ddf84b487f876d67d7e05ae692',
        'supportDeliveryBaseUrl': 'https://dmsitafd.azurefd.net/api/v1',
        'supportDeliveryApi': {
            'BatchDeliveriesRequest':'/Batch/Deliveries?includeAssignments=%s',
            'GetDeliveriesCounts': '/Landing/Requests/%s/Count',
            'SupportDeliveries': 'https://servicesint.azurewebsites.net/#/supportdelivery/mydeliveries',
            'FilteredSupportDeliveries': 'https://servicesint.azurewebsites.net/#/supportdelivery/mydeliveries?status=%s',
        },
        'cpeProfileLink': 'https://aka.ms/cpeprofile?filter=CALCAccountParenting~2FTPID%20eq%20%27{tpid}%27&chromeless=1'
    }
};
