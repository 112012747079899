import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://rmux.azureedge.net/prod-ux',
  serviceEndPoints: [
    {
      clientId: 'e42b0f1c-114a-42a1-853a-089f1ab22b22',
      serviceEndPoint: 'https://professionalservices.microsoft.com/rmsvc/',
    },
    {
      clientId: 'e42b0f1c-114a-42a1-853a-089f1ab22b22',
      serviceEndPoint: 'https://fd-rmxcsvc-prod-hea9ftfragehe3b9.b02.azurefd.net',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/',
    },
    {
      clientId: '6db56845-5ebb-4b74-88d0-28cc0e41e163',
      serviceEndPoint: 'https://professionalservices.microsoft.com/dm/',
    }
  ],
  RmAppConfiguration: {
    'rmServiceBaseUri': 'https://professionalservices.microsoft.com/rmsvc',
    'rmGraphQLBaseUri': 'https://fd-rmxcsvc-prod-hea9ftfragehe3b9.b02.azurefd.net',
    'apimSubscriptionKey': '417a9ef072044af7857c0def6a5ed7af',
    'PartnerEndpoints': [
      {
        'Endpoint': 'https://professionalservices.microsoft.com/rmsvc/',
        'ResourceId': 'e42b0f1c-114a-42a1-853a-089f1ab22b22'
      }
    ],
    'opportunities': {
      'msxLink': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=opportunity&id={0}'
    },
    'cloudConsumption': {
      'sccr': 'https://microsoft.sharepoint.com/teams/esbi/cloud%20consumption/pages/default.aspx',
      'cePortalForConsumption': 'https://cecustomers.microsoftonline.com/',
      'us_bsoReporting': 'https://microsoft.sharepoint.com/teams/usmobpo/bso/SitePages/reports-for-msxi.aspx?chromeless=True'
    },
    'serviceFinancials': {
      'tamRolePerformanceDashboard': 'https://msit.powerbi.com/groups/me/reports/c9c3a607-be68-49fe-a89f-c75409c8f0fa/ReportSectionf7f244c90383db8a1c8d',
      'servicesInsightsES': 'https://msit.powerbi.com/groups/56e3ce91-0de0-43bb-a4b3-245db704ea4a/dashboards/283edfd8-d60b-451b-b296-c25b1e09aa'
    },
    'featureFlighting': {
      'appName': 'Relationship Management Experience',
      'environment': 'prod'
    },
    'oneProfile': 'https://oneprofileprodapipack.trafficmanager.net/api/v1/profile/searchprofile?searchText=%s',
    'axisConfig': {
      'requestDetails': 'https://esxp.microsoft.com/#/supportdelivery/requestdetails/%s'
    },
    'msxConfig': {
      'engagementMsxUrlTemplate': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=msp_engagement&id=%s',
      'milestoneMsxUrlTemplate': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=msp_engagementmilestone&id=%s'
    },
    'supportDeliveryApimSubscriptionKey': 'bcdb82ce854046859e1eb53fc166b9f4',
    'supportDeliveryBaseUrl': 'https://professionalservices.microsoft.com/dm/api/v1',
    'supportDeliveryApi': {
      'BatchDeliveriesRequest':'/Batch/Deliveries?includeAssignments=%s',
      'GetDeliveriesCounts': '/Landing/Requests/%s/Count',
      'SupportDeliveries': 'https://esxp.microsoft.com/#/supportdelivery/mydeliveries',
      'FilteredSupportDeliveries': 'https://esxp.microsoft.com/#/supportdelivery/mydeliveries?status=%s'
    }
  }
};
