import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const dev: IPartnerAppSettings = {
    cdnBaseUrl: 'http://localhost:5003',
    serviceEndPoints: [
        {
            clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
            serviceEndPoint: 'https://professionalservicesint.microsoft.com/rmsvcsit/'
            //serviceEndPoint: 'http://localhost:32073',
        },
        {
            clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
            serviceEndPoint: 'https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net'
            // serviceEndPoint: 'https://localhost:6001'
        },
        {
            clientId: '00000003-0000-0000-c000-000000000000',
            serviceEndPoint: 'https://graph.microsoft.com/'
        },
        {
            clientId: 'c8955af6-b4a7-4e96-86b7-ad0930c25fa7',
            serviceEndPoint: 'https://dmsit.azurewebsites.net/'
        }
    ],
    RmAppConfiguration: {
         //'rmServiceBaseUri': 'http://localhost:32073',
         // 'rmGraphQLBaseUri': 'https://localhost:6001'
    }
};
