import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://rmux-nonprod.azureedge.net/servicesuat-ux',
  serviceEndPoints: [
    {
      clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/rmsvcsit/',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/',
    },
    {
      clientId: '181ef486-9e47-4ad6-9454-7a326432dc00',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/dmuat/',
    },
    {
        clientId: 'c1df0735-5f8f-4429-9977-80e732362bdd',
        serviceEndPoint: 'https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net'
    }
  ],
  RmAppConfiguration: {
    'axisConfig': {
      'requestDetails': 'https://servicesuat.microsoft.com/#/supportdelivery/requestdetails/%s'
    },
    'msxConfig': {
      'engagementMsxUrlTemplate': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=msp_engagement&id=%s',
      'milestoneMsxUrlTemplate': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=msp_engagementmilestone&id=%s'
    },
    'supportDeliveryApimSubscriptionKey': 'd3a845ddf84b487f876d67d7e05ae692',
    'supportDeliveryBaseUrl': 'https://professionalservicesint.microsoft.com/dmuat/api/v1',
    'supportDeliveryApi': {
      'BatchDeliveriesRequest': '/Batch/Deliveries?includeAssignments=%s',
      'GetDeliveriesCounts': '/Landing/Requests/%s/Count',
      'SupportDeliveries': 'https://servicesuat.microsoft.com/#/supportdelivery/mydeliveries',
      'FilteredSupportDeliveries': 'https://servicesuat.microsoft.com/#/supportdelivery/mydeliveries?status=%s'
    }
  }
};
